<template>
    <div class="modalcgu">
<div class="modalcgu-content">

<h3>Privacy Policy / Terms of Service</h3>  
<h3>Last Updated: 10/10/2023</h3>

<h3>1. Introduction</h3>

<p>Welcome to daily-surf.com. This Privacy Policy and Terms of Service ("Policy") is intended to inform you about the general practices we follow and the terms and conditions that govern your use of our website at <a href="daily-surf.com">daily-surf.com</a> ("Website").</p>

<h3>2. No Data Collection</h3>

<p>We want to assure you that we do not collect any personal information or data from our users. Our website is designed to provide information, services, or resources without requiring you to provide any personal information.</p>

<h3>3. Use of Cookies</h3>

<p>We may use cookies or similar technologies to enhance the user experience on our Website. These cookies are solely used for functionality and do not collect any personal information.</p>

<h3>4. Links to External Websites</h3>

<p>Our Website may contain links to external websites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot be responsible for their privacy policies or terms of service.</p>

<h3>5. Changes to this Policy</h3>

<p>We reserve the right to update or change this Policy at any time. Any changes will be effective immediately upon posting the revised Policy on our Website. Your continued use of the Website after any such changes constitutes your acceptance of the new Policy.</p>

<h3>6. Contact Us</h3>

<p>If you have any questions or concerns about this Privacy Policy or Terms of Service, please contact us at <a href="https://twitter.com/daily_surf_com">https://twitter.com/daily_surf_com</a>.</p>


<div class="sharing" >
  
<a href="https://twitter.com/daily_surf_com">
  <p>Follow us on Twitter ! (X)</p>
<div  class="share"><svg width="512" height="512" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231l5.45-6.231Zm-1.161 17.52h1.833L7.045 4.126H5.078L17.044 19.77Z"/>
</svg>
<a>Twitter</a>
</div></a></div>
<div class="modalcgu-footer">
  <a class="close" v-on:click="close()" >Close</a>
</div>
</div>
</div>

</template>

<script>

export default {
name: 'ModalCGU',

methods: {
  close: function() {
      document.querySelector('.modalcgu').style.display = 'none';
  }
}
}
</script>


<style scoped>
.sharing svg {
width: 5%;
height: 5%;
padding-bottom: 20px;
}

.sharing a {
padding-left: 10px;
padding-bottom: 10px;

}

.share {
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

}


.modalcgu {
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.5);
display: block;
overflow-y: auto;
}

.modalcgu-content {
background-color: #ffffff;
width: 50%;

margin: 0 auto;
margin-top: 10vh;
padding: 20px;
border-radius: 10px;

}
.close {
cursor: pointer;
border: 1px solid black;
border-radius: 5px;
padding: 5px;

}


</style>