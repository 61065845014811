<template>
          <div class="modal">
    <div class="modal-content">
      <h4>Rules</h4>
      <p>The aim is to find the word</p>
      <p>The word contains as many letters as there are hyphens.</p>
      <p>The word contains only letters.</p>
      <p>To submit a word, use the 'enter' key</p>
      <p>Good luck!</p>

      <div class="sharing" >
        
      <a href="https://twitter.com/daily_surf_com">
        <p>Follow us on Twitter ! (X)</p>
      <div  class="share"><svg width="512" height="512" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231l5.45-6.231Zm-1.161 17.52h1.833L7.045 4.126H5.078L17.044 19.77Z"/>
      </svg>
      <a>Twitter</a>
      </div></a></div>
      <div class="modal-footer">
        <a class="close" v-on:click="close()" >Close</a>
      </div>
    </div>
    </div>

</template>

<script>

export default {
    name: 'ModalRules',

    methods: {
        close: function() {
            document.querySelector('.modal').style.display = 'none';
        }
    }
}
</script>


<style scoped>
.sharing svg {
  width: 5%;
  height: 5%;
  padding-bottom: 20px;
}

.sharing a {
  padding-left: 10px;
  padding-bottom: 10px;

}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
}


.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  width: 50%;

  margin: 0 auto;
  margin-top: 10vh;
  padding: 20px;
  border-radius: 10px;
}



/* mobile */
@media screen and (max-width: 480px) {
  .modal-content {
    width: 80%;
  }
}



.close {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;

}


</style>