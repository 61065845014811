<template>
    <div>
        <div id="input-area">
            <div class="input-ligne">
                <div class="input-lettre input-lettre_bg" data-lettre="A">A</div>
                <div class="input-lettre input-lettre_bg" data-lettre="Z">Z</div>
                <div class="input-lettre input-lettre_bg" data-lettre="E">E</div>
                <div class="input-lettre input-lettre_bg" data-lettre="R">R</div>
                <div class="input-lettre input-lettre_bg" data-lettre="T">T</div>
                <div class="input-lettre input-lettre_bg" data-lettre="Y">Y</div>
                <div class="input-lettre input-lettre_bg" data-lettre="U">U</div>
                <div class="input-lettre input-lettre_bg" data-lettre="I">I</div>
                <div class="input-lettre input-lettre_bg" data-lettre="O">O</div>
                <div class="input-lettre input-lettre_bg" data-lettre="P">P</div>
            </div>
            <div class="input-ligne">
                <div class="input-lettre input-lettre_bg" data-lettre="Q">Q</div>
                <div class="input-lettre input-lettre_bg" data-lettre="S">S</div>
                <div class="input-lettre input-lettre_bg" data-lettre="D">D</div>
                <div class="input-lettre input-lettre_bg" data-lettre="F">F</div>
                <div class="input-lettre input-lettre_bg" data-lettre="G">G</div>
                <div class="input-lettre input-lettre_bg" data-lettre="H">H</div>
                <div class="input-lettre input-lettre_bg" data-lettre="J">J</div>
            <div class="input-lettre input-lettre_bg" data-lettre="K">K</div>
            <div class="input-lettre input-lettre_bg" data-lettre="L">L</div>
            <div class="input-lettre input-lettre_bg" data-lettre="M">M</div>
        </div>
        <div class="input-ligne">   
            <div class="input-lettre input-lettre_bg" data-lettre="W">W</div>
            <div class="input-lettre input-lettre_bg" data-lettre="X">X</div>
            <div class="input-lettre input-lettre_bg" data-lettre="C">C</div>
            <div class="input-lettre input-lettre_bg" data-lettre="V">V</div>
            <div class="input-lettre input-lettre_bg" data-lettre="B">B</div>
            <div class="input-lettre input-lettre_bg" data-lettre="N">N</div>
            <div class="input-lettre input-lettre_bg input-lettre-effacer" data-lettre="Backspace">⌫</div>
            <div class="input-lettre input-lettre_bg input-lettre-entree" data-lettre="Enter">↲</div>
        </div>
    </div>
</div></template>

<script>
export default {
    name: 'KeyBoard',
    data() {
        return {

        }
    },
    props: {
        liste_lettre_vertes: [],
        liste_lettre_orange: [],
        liste_lettre_grises: [],
    },
    mounted(){
        document.querySelectorAll('.input-lettre').forEach((element) => {
            element.addEventListener('click', () => {
               console.log(element.dataset.lettre);
                // create keydown   event
                var event = new KeyboardEvent('keydown', {
                    key: element.dataset.lettre,
                    code: element.dataset.lettre,
                    charCode: element.dataset.lettre.charCodeAt(0),
                    keyCode: element.dataset.lettre.charCodeAt(0),
                    which: element.dataset.lettre.charCodeAt(0),
                    bubbles: true,
                    cancelable: true,
                    repeat: false
                });

                // dispatch event
                document.dispatchEvent(event);
            });


        });

    },


    methods: {

    }
}


</script>

<style scoped>




#input-area {
position: bottom;
}

.input-ligne {
  display: flex;
  justify-content: center;
  gap: 0.25em;
  width: 100%;
}

.input-ligne + .input-ligne {
  margin-top: 0.25em;
}

.input-lettre {
  font-size: 1.3em;
  font-weight: bolder;
  display: inline-block;
  border: 1px solid #000000;
  border-radius: 5px;
  user-select: none;
  flex-grow: 1;
  flex-shrink: 0;
  height: 45px;
  min-width: 30px;
  max-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "RobotoMono Regular", monospace; 
}

.input-lettre:hover{
    background-color: #d1d1d1;
    cursor: pointer;
}


/* desktop */
@media screen and (min-width: 600px) {

    .input-lettre_bg{
        background-image: url('../assets/wallpwave.png');
        background-size: cover;
    }
}

</style>
