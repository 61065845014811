<template>
<nav>
    <div class="nav-wrapper">
      <p class="date">{{today}}</p>
        <img src="../assets/DailySurf.svg" alt="logo" class="brand-logo" >
        <img src="../assets/DS.png" alt="logo" class="brand-logo2" >
        
        <div class="wrap_info">
        <div class="info"  v-on:click="open()"><svg  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M11 17h2v-6h-2v6Zm1-8q.425 0 .713-.288T13 8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8q0 .425.288.713T12 9Zm0 13q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/>
</svg></div>
<div class="info"  v-on:click="opencgu()"><svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill="#000000" d="M8.75.75V2h.985c.304 0 .603.08.867.231l1.29.736c.038.022.08.033.124.033h2.234a.75.75 0 0 1 0 1.5h-.427l2.111 4.692a.75.75 0 0 1-.154.838l-.53-.53l.529.531l-.001.002l-.002.002l-.006.006l-.006.005l-.01.01l-.045.04c-.21.176-.441.327-.686.45C14.556 10.78 13.88 11 13 11a4.498 4.498 0 0 1-2.023-.454a3.544 3.544 0 0 1-.686-.45l-.045-.04l-.016-.015l-.006-.006l-.004-.004v-.001a.75.75 0 0 1-.154-.838L12.178 4.5h-.162c-.305 0-.604-.079-.868-.231l-1.29-.736a.245.245 0 0 0-.124-.033H8.75V13h2.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1 0-1.5h2.5V3.5h-.984a.245.245 0 0 0-.124.033l-1.289.737c-.265.15-.564.23-.869.23h-.162l2.112 4.692a.75.75 0 0 1-.154.838l-.53-.53l.529.531l-.001.002l-.002.002l-.006.006l-.016.015l-.045.04c-.21.176-.441.327-.686.45C4.556 10.78 3.88 11 3 11a4.498 4.498 0 0 1-2.023-.454a3.544 3.544 0 0 1-.686-.45l-.045-.04l-.016-.015l-.006-.006l-.004-.004v-.001a.75.75 0 0 1-.154-.838L2.178 4.5H1.75a.75.75 0 0 1 0-1.5h2.234a.249.249 0 0 0 .125-.033l1.288-.737c.265-.15.564-.23.869-.23h.984V.75a.75.75 0 0 1 1.5 0Zm2.945 8.477c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L13 6.327Zm-10 0c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L3 6.327Z"/>
</svg></div></div>

  </div>
</nav>


</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      today: new Date().toLocaleDateString()
    }
  },
  methods: {
    open: function() {
      document.querySelector('.modal').style.display = 'block';
    },
    opencgu: function() {
      document.querySelector('.modalcgu').style.display = 'block';
    }

  }
}
</script>

<style scoped>

/* smartphone */
@media screen and (max-width: 600px) {
  .brand-logo2 {
    
    width: 25%;
    float: left;
  }

  .brand-logo {
    display: none;
  }

  .wrap_info{
  margin-top: 2vh;
  float: right;
}

.date{
  font-size: 1em;
  font-weight: bold;

}

.nav-wrapper{

margin-right: 20px;
margin-left: 20px;
display: flex;
justify-content: space-between;
}

}

/* desktop */
@media screen and (min-width: 600px) {

.wrap_info{
  margin-top: 2vh;
  float: right;
}

.date{
  float: left;
  font-size: 1.5em;
  font-weight: bold;

}

.brand-logo {
  margin-top: 2vh;
width: 20%;
}

.brand-logo2 {
  display: none;
}

.nav-wrapper{

  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
}

}
.nav-wrapper {

    margin-bottom: 10vh;
  background-color: #ffffff;
 
}

.info {
  width: 30px;
  float: right;
  cursor: pointer;
  margin-left: 10px;
}





</style>