<template>
    <div class="main">
      <div class="alert-box failure" id="alert">This word doesn't exists</div>
        <ModalCGU  v-show="false" ></ModalCGU>
        <ModalRules v-show="status === true" ></ModalRules> 
    
      <div class="container">

       <div id="input1" class="text_center">
        <span v-for="index in len" :key="index.id" class="letter ">
          <span class="span1 result" v-if="spacepos[index-1] === '0'">{{ mots[1][0][index-1] }}</span>
          <span class="spacing" v-else>{{ spacepos[index-1]}} </span>
        </span>
      </div>
        <div id="result1">
          <p class="noin">{{ result }}</p>
           <span v-for="index in len+2" :key="index.id"  class="result">
           <span  v-if="mots[1][1][index-1] == '0'" class="false">{{ mots[1][0][index-1] }}</span> 
           <span  v-if="mots[1][1][index-1]  == '1'" class="good">{{ mots[1][0][index-1] }}</span> 
            <span  v-if="mots[1][1][index-1]  == '2'" class="semigood">{{ mots[1][0][index-1] }}</span>
          </span>
        </div>

        <div id="input2" class="noin">
        <span v-for="index in len" :key="index.id" class="letter " >
          <span class="span2 result" v-if="spacepos[index-1] === '0'">{{ mots[2][0][index-1] }}</span>
          <span  v-else>{{ spacepos[index-1] }} </span>
        </span>
        </div>

        <div id="result2">
          <span v-for="index in len+2" :key="index.id"  class="result" >
           <span  v-if="mots[2][1][index-1] == '0'" class="false">{{ mots[2][0][index-1] }}</span> 
           <span  v-if="mots[2][1][index-1]  == '1'" class="good">{{ mots[2][0][index-1] }}</span> 
            <span  v-if="mots[2][1][index-1]  == '2'" class="semigood">{{ mots[2][0][index-1] }}</span>
          </span>
        </div>


        <div id="input3" class="noin">
        <span v-for="index in len" :key="index.id" class="letter " >
          <span class="span3 result" v-if="spacepos[index-1] === '0'">{{ mots[3][0][index-1] }}</span>
          <span v-else>{{ spacepos[index-1] }} </span>
        </span>
        </div>

        <div id="result3">
          <span v-for="index in len+2" :key="index.id"  class="result">
           <span  v-if="mots[3][1][index-1] == '0'" class="false">{{ mots[3][0][index-1] }}</span> 
           <span  v-if="mots[3][1][index-1]  == '1'" class="good">{{ mots[3][0][index-1] }}</span> 
            <span  v-if="mots[3][1][index-1]  == '2'" class="semigood">{{ mots[3][0][index-1] }}</span>
          </span>
        </div>
      

        <div id="input4" class="noin">
        <span v-for="index in len" :key="index.id" class="letter " >
          <span class="span4 result" v-if="spacepos[index-1] === '0'">{{ mots[4][0][index-1] }}</span>
          <span v-else>{{ spacepos[index-1] }} </span>
        </span>
        </div>

        <div id="result4">
          <span v-for="index in len+2" :key="index.id"  class="result">
           <span  v-if="mots[4][1][index-1] == '0'" class="false">{{ mots[4][0][index-1] }}</span> 
           <span  v-if="mots[4][1][index-1]  == '1'" class="good">{{ mots[4][0][index-1] }}</span> 
            <span  v-if="mots[4][1][index-1]  == '2'" class="semigood">{{ mots[4][0][index-1] }}</span>
          </span>
        </div>

        <div id="input5" class="noin">
        <span v-for="index in len" :key="index.id" class="letter" >
          <span class="span5 result" v-if="spacepos[index-1] === '0'">{{ mots[5][0][index-1] }}</span>
          <span v-else>{{ spacepos[index-1] }} </span>
        </span>
        </div>

        <div id="result5">
          <span v-for="index in len+2" :key="index.id"  class="result">
           <span  v-if="mots[5][1][index-1] == '0'" class="false">{{ mots[5][0][index-1] }}</span> 
           <span  v-if="mots[5][1][index-1]  == '1'" class="good">{{ mots[5][0][index-1] }}</span> 
            <span  v-if="mots[5][1][index-1]  == '2'" class="semigood">{{ mots[5][0][index-1] }}</span>
          </span>
        </div>

        <div id="input6" class="noin">
        <span v-for="index in len" :key="index.id" class="letter" >
          <span class="span6 result" v-if="spacepos[index-1] === '0'">{{ mots[6][0][index-1] }}</span>
          <span v-else>{{ spacepos[index-1] }} </span>
        </span>
        </div>

        <div id="result6">
          <span v-for="index in len+2" :key="index.id"  class="result">
           <span  v-if="mots[6][1][index-1] == '0'" class="false">{{ mots[6][0][index-1] }}</span> 
           <span  v-if="mots[6][1][index-1]  == '1'" class="good">{{ mots[6][0][index-1] }}</span> 
            <span  v-if="mots[6][1][index-1]  == '2'" class="semigood">{{ mots[6][0][index-1] }}</span>
          </span>
        </div>

      </div>
      <div class="sharing" v-if="trynumber ===7">
        <p> Share your result !</p>
        <p>Definition : {{ definition }}</p>
       
        <div  > <!-- v-if="trynumber ===7"-->
          <button v-on:click="copyclipboard()" class="buttonshare">Copy result</button>
          <div>You scored {{score}}/6 on Daily Surf !</div>
          <div id="finalresult" class="final">
          <div v-for="res in mots" :key="res.id" class="fle">
            <div v-for="res2 in res[1]" :key="res2.id" >
           <span v-if="res2 == '0'" class="square">🟦</span>
            <span v-if="res2  == '1'" class="square">🟩</span>
              <span v-if="res2  == '2'" class="square">🟨</span>
            </div>
            <br>
            <span>

            </span>
          </div>
        </div>
        </div>


        <div v-on:click="tweet()" class="share"><svg width="512" height="512" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231l5.45-6.231Zm-1.161 17.52h1.833L7.045 4.126H5.078L17.044 19.77Z"/>
</svg>
<a>Twitter</a>
</div>
      </div>

      <KeyBoard class="kb" :liste_lettre_vertes="this.liste_lettre_vertes" :liste_lettre_orange="this.liste_lettre_orange" :liste_lettre_grises="this.liste_lettre_grises"  v-if="trynumber !== 7"></KeyBoard>

   
    </div>
  </template>
  
  <script>
   
    import KeyBoard from '@/components/KeyBoard.vue'
    import ModalRules from './ModalRules.vue';
    import ModalCGU from '@/components/CGU.vue';
    import surfWords from '!!raw-loader!../assets/data/surf.txt';

    import words_dictionary from '../assets/data/words_dictionary.json';


  export default {
    name: 'HomePage',
    components: {
      KeyBoard,
      ModalRules,
      ModalCGU
    },
    data () {
      return {

        mots : {
            1: ['',''],
            2:  ['',''],
            3:  ['',''],
            4:  ['',''],
            5:  ['',''],
            6:  ['','']
        },
        trynumber: 1,
        len: 0,
        firstletter: '',
        result: '',
        score: 0,
        definition: '',
        input_size: 0,
        currspan: 1,
        liste_lettre_vertes: [],
        liste_lettre_orange: [],
        liste_lettre_grises: [],
        status: false,
        spacepos: "",

        possible_words: [],
        word_to_find: "",
      }
    },
    beforeMount(){
      function setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires="+d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
          let name = cname + "=";
          let ca = document.cookie.split(';');
          for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }

        function checkCookie() {
          let rules = getCookie("PrintRules");
          if (rules != "") {
            console.log("cookie found");
              return false;
          } else {
              setCookie("PrintRules", "true", 365);
              return true;
          }
        }

      this.status = checkCookie();

      

      const date = new Date().toISOString().slice(0, 10);

      var formData = surfWords;
      var decodedData = atob(formData)
      try {
        var data = JSON.parse(decodedData);
      } catch (error) {
        console.error(' error', error);
      }


        let foundWord = null;
          let foundDefinition = null;

        for (const word of data) {
            if (word.date === date) {
              foundWord = word.word;
              foundDefinition = word.definition;
              break;
            }
          }
        

      this.word_to_find = foundWord;
      this.definition = foundDefinition;

      this.len = this.word_to_find.length;
      this.firstletter = this.word_to_find[0];
      this.spacepos = "0".repeat(this.len);

      for (var i = 1; i < 7; i++) {
            this.mots[i][0] = this.firstletter+ ".".repeat(this.len-1);
        }


      const possiblewords = words_dictionary

      const keys = Object.keys(possiblewords);
      for (const key of keys) {
     
          if (key.length == this.len && key[0] == this.firstletter.toLowerCase()) {
           
            this.possible_words.push(key);
          }
      }

  


        /*
        axios.get(process.env.VUE_APP_HOST+'/len')
        .then(response => {
           this.len = parseInt(response.data.result[0]);
           this.firstletter = response.data.result[1];
           this.definition = response.data.definition;
           this.status = true;
           this.spacepos = response.data.spacepos;
    
           for (var i = 1; i < 7; i++) {
            this.mots[i][0] = this.firstletter+ ".".repeat(this.len-1);
        }

        

        })
        .catch(error => {
            console.log(error);
        });

       */




    },

    mounted(){

      
    


      function possible(data,possible_words){
      
        if (possible_words.includes(data.get('input').toLowerCase())){
          return true;
        }else{
          return false;}
    
      }

      function check(data,word_to_find){
        let findingwordCount = {};
        let input = data.get('input').toLowerCase();
        /* Make input and findingword lowercase */
        
        let findingword = word_to_find.toLowerCase();


        for (let i = 0; i < findingword.length; i++) {
          const letter = findingword[i];
          findingwordCount[letter] = (findingwordCount[letter] || 0) + 1;
        }

    

        let result = "";
        for (let i = 0; i < input.length; i++) {
          /* Check if the letter is correctly placed */
          if (findingword[i] === input[i]) {
            result += "1";
            findingwordCount[input[i]]--;
          } else if (findingword[i] !== input[i]) {
            result += "0";
          }
        }

       

        for (let i = 0; i < result.length; i++) {
          if (result[i] === '0') {
            const contains = findingword.includes(input[i]);
            if (contains) {
              if (findingwordCount[input[i]] > 0) {
                result = result.slice(0, i) + "2" + result.slice(i + 1);
                findingwordCount[input[i]]--;
              }
            }
          }
        }
        return result;
        }

      


      
      function MAJcolors(mot,liste_lettre_vertes,liste_lettre_orange,liste_lettre_grises){
       
        for (var i = 0; i < mot[0].length; i++) {
          if (mot[1][i] == '1') {
            liste_lettre_vertes.push(mot[0][i].toLowerCase());
          }
          else if (mot[1][i] == '2') {
            liste_lettre_orange.push(mot[0][i].toLowerCase());
          }
          else if (mot[1][i] == '0') {
            liste_lettre_grises.push(mot[0][i].toLowerCase());
          }
        }

        /* for each remove duplicates */
        liste_lettre_vertes = [...new Set(liste_lettre_vertes)];
        liste_lettre_orange = [...new Set(liste_lettre_orange)];
        liste_lettre_grises = [...new Set(liste_lettre_grises)];

        /* if letter is in orange and green, remove from orange */
        for (let i = 0; i < liste_lettre_vertes.length; i++) {
          if (liste_lettre_orange.includes(liste_lettre_vertes[i])) {
            liste_lettre_orange.splice(liste_lettre_orange.indexOf(liste_lettre_vertes[i]),1);
          }
        }

        /* if letter is in green and grey, remove from grey */
        /* if letter is in orange and grey, remove from grey */
        for (let i = 0; i < liste_lettre_grises.length; i++) {
          if (liste_lettre_orange.includes(liste_lettre_grises[i])) {
            liste_lettre_grises.splice(liste_lettre_grises.indexOf(liste_lettre_grises[i]),1);
          }
          if (liste_lettre_vertes.includes(liste_lettre_grises[i])) {
            liste_lettre_grises.splice(liste_lettre_grises.indexOf(liste_lettre_grises[i]),1);
          }
        }

        document.querySelectorAll('.input-lettre').forEach((element) => {
        if(liste_lettre_vertes.includes((element.dataset.lettre).toLowerCase())){
                element.classList.add('input-lettre-verte');
                element.classList.remove('input-lettre_bg');
            
            }
        if(liste_lettre_orange.includes((element.dataset.lettre).toLowerCase())){
            element.classList.add('input-lettre-orange');
            element.classList.remove('input-lettre_bg');
        }
        if(liste_lettre_grises.includes((element.dataset.lettre).toLowerCase())){
            element.classList.add('input-lettre-grise');
            element.classList.remove('input-lettre_bg');
        }
        });


        return liste_lettre_vertes,liste_lettre_orange,liste_lettre_grises;

      }

        
        /*lister enter key*/
        document.addEventListener('keydown', (event) => {
            const keyName = event.key;
    
            if(keyName == 'Enter'){
                if (this.trynumber == 7){
                    console.log("End of game");
                    return;
                }
                else {
                var nextinput = "input" + this.trynumber;
            
                var input = document.getElementById(nextinput);
  
                var mot = this.mots[this.trynumber][0];
                if (mot.length != this.len) {
                    return;
                }

            
                
    


         
                var data =  new FormData();
                data.append('input', mot);

              

                if (!possible(data,this.possible_words)){
                  var alert = document.getElementById('alert');
                    alert.style.display = "block";
                   setTimeout(function(){ 
                      
                      alert.style.display = "none"; 
                    }, 3000);
                  return;
                }else{
                  this.result = check(data,this.word_to_find);
                    this.mots[this.trynumber][1] = this.result;
                    
                    this.liste_lettre_vertes, this.liste_lettre_orange, this.liste_lettre_grises = MAJcolors(this.mots[this.trynumber], this.liste_lettre_vertes, this.liste_lettre_orange, this.liste_lettre_grises)

                 
                    input.classList.add("noin");
                    if (this.result.split('1').length - 1 == this.len) {
                        console.log("End of game");
                        this.score = this.trynumber;
                        this.trynumber = 7;
                        return;
                    }else{
                      
                      this.trynumber++;
                      if (this.trynumber == 7){
                        return;
                      }
                      this.currspan = 1;
                      nextinput = "input" + this.trynumber;
                    
                      input = document.getElementById(nextinput);
                      input.classList.remove("noin");
                   
                    }

                  /**
                axios.post(process.env.VUE_APP_HOST+'/check', data)
                  .then(response => {
                    
                    this.result = response.data.result;
                    this.mots[this.trynumber][1] = this.result;
                    
                    this.liste_lettre_vertes, this.liste_lettre_orange, this.liste_lettre_grises = MAJcolors(this.mots[this.trynumber], this.liste_lettre_vertes, this.liste_lettre_orange, this.liste_lettre_grises)

                 
                    input.classList.add("noin");
                    if (this.result.split('1').length - 1 == this.len) {
                        console.log("End of game");
                        this.score = this.trynumber;
                        this.trynumber = 7;
                        return;
                    }else{
                      
                      this.trynumber++;
                      this.currspan = 1;
                      nextinput = "input" + this.trynumber;
                    
                      input = document.getElementById(nextinput);
                      input.classList.remove("noin");
                   
                    }

                  });*/
          


                    }
                      
                  }
              /*  )  .catch(function (error) {
                    console.log(error);
                   
                    // add keframe animation
                    var alert = document.getElementById('alert');
                    alert.style.display = "block";
                   setTimeout(function(){ 
                      
                      alert.style.display = "none"; 
                    }, 3000);
                  });*/


                


    
            }else{
              var nextspan = "span" + this.trynumber;
              var span = document.getElementsByClassName(nextspan);
     
              if (keyName == 'Backspace' && this.currspan > 0 && this.currspan <= this.len) {
                this.currspan--;
             
                span[this.currspan].innerHTML = '.';

                this.mots[this.trynumber][0] = this.mots[this.trynumber][0].substring(0, this.currspan) + '.' + this.mots[this.trynumber][0].substring(this.currspan + 1);

                return;
              } else if (keyName.match(/[a-z]/i) && this.currspan < this.len && keyName != 'Backspace' && keyName.length == 1 ) {
                    
                    span[this.currspan].innerHTML = keyName;

                    this.mots[this.trynumber][0] = this.mots[this.trynumber][0].substring(0, this.currspan) + keyName + this.mots[this.trynumber][0].substring(this.currspan + 1);
                    this.currspan++;
                    return; 
                }

            }
      
          }, false);
    },

    methods: {

      




      async copyclipboard(){
        var texte = document.getElementById('finalresult')
       
       // var each = texte.textContent.length / this.len;
       var taille = texte.textContent.length;

        var tab = [];
        let i = 0;
        tab.push("You scored " + this.score + "/6 on Daily Surf !")
       do {
          tab.push(texte.textContent.slice(i, i+(this.len*2)))
          i = i+(this.len*2)
        } while (i<taille+1);
        tab.push("https://daily-surf.com")
        tab.push("Try it now !")
       try {
          await navigator.clipboard.writeText(tab.join('\n'));
        console.log('Page URL copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }

      

      //put text on clipboard
      /*var input = document.createElement('input')
      input.setAttribute('value', texte.textContent)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      alert('copied')*/
      },

      tweet(){
        var texte = document.getElementById('finalresult')
       
        var taille = texte.textContent.length;
      
        var tab = [];
        let i = 0;
        tab.push("You scored " + this.score + "/6 on Daily Surf !%0A")
       do {
          tab.push(texte.textContent.slice(i, i+(this.len*2)))
          i = i+(this.len*2)
        } while (i<taille+1);
        tab.push("https://daily-surf.com%0A")

        var url = "https://twitter.com/intent/tweet?text=" + tab.join("%0A") + "&hashtags=dailysurf"

        window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );


      },





}
    }
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

.sharing svg {
  width: 5%;
  height: 5%;
  padding-bottom: 20px;
}

.sharing a {
  padding-left: 10px;
  padding-bottom: 10px;

}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
}




/* smartphone */
@media screen and (max-width: 600px) {

  .letter{
   display: inline-block;

  }


  .sharing {
  

  border: #a9a9a9 solid 1px;
  border-radius: 5px;
  margin-right: 3vw;
  margin-left: 3vw;
  margin-top: 5vh;
  padding-top: 20px;
 padding-bottom: 20px;
}


.spacing{
padding-left: 20px;
padding-right: 20px;
}
  


.noin {
    display: none;
}

.result{
  width: 1.5ch;
  margin: 2em auto;
  border: none;
  padding: 0;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/55ch 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: 0.5ch;
}

.container{
  max-width: 90%;
  margin: auto;
  overflow: auto;
}

.good{
  color: greenyellow;
}

.semigood{
  color: rgb(255, 196, 0);
}



}

/* desktop */
@media screen and (min-width: 600px) {
  .sharing {
  

  border: #a9a9a9 solid 1px;
  border-radius: 5px;
  margin-right: 30vw;
  margin-left: 30vw;
  margin-top: 5vh;
  padding-top: 20px;
 padding-bottom: 20px;
}


.spacing{
padding-left: 20px;
padding-right: 20px;
}
  
input {
display: block;
  margin: 2em auto;
  border: 1px solid dimgrey;
  padding: 0;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1rem, transparent 0, transparent 1.5rem) 0 100%/55rem 2px no-repeat;
  font: 3rem droid sans mono, consolas, monospace;
  letter-spacing: 0.5rem;
 
}
input:focus {
  outline: none;
  color: dodgerblue;
}


.noin {
    display: none;
}

.result{
  width: 1.5ch;
  margin: 2em auto;
  border: none;
  padding: 0;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/55ch 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: 0.5ch;
  
}

.good{
  color: greenyellow;
}

.semigood{
  color: rgb(255, 196, 0);
}


}




.final{
  display: block;

}

.fle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.buttonshare {

  cursor: pointer;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  border: #a9a9a9 solid 1px;
  background-color: #ffffff;
}

.alert-box {
	padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;  
}

/* The close button */
.failure {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    display: none;
    position: fixed;
    top: 10%;
    
    animation: fadeInAndOut 2s ease-in-out 0s 1 normal forwards running;
}

@keyframes fadeInAndOut {
  0% {
    opacity:0;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.kb {
margin-top: 5vh;
position: fixed;
bottom: 10%;
left: 0;
right: 0;
margin-left: auto;
margin-right: auto;
padding-left: 10px;
padding-right: 10px;


}



</style>
  