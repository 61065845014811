<template>
  <div id="app">
    <NavBar/>
    <HomePage/>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
import NavBar from './components/NavBar.vue'
export default {
  name: 'App',
  components: {
    HomePage,
    NavBar

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 95vh;

}

.input-lettre-verte{
    background-color: #97f897;
}

.input-lettre-orange{
    background-color: #fdcb80;
}

.input-lettre-grise{
    background-color: #aaaaaa;
}
</style>
